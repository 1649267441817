import React from "react";

import Layout from "./layout";
import SEO from "./seo";
import DeviceImage from "./device-image";

import DownloadButton from "./download-button";

const COLORS = {
  pt: "#2e7d32",
  fr: "#0055A4",
};

const CountryPage = ({
  appName,
  countryCode,
  androidBtnConfig,
  iosBtnConfig,
  headline1 = "tbd",
  headline2 = "tbd",
}) => (
  <Layout countryCode={countryCode} appName={appName}>
    <SEO title="Rádio Tuga" />
    <div className="px-4 md:px-8">
      <h2 className="text-2xl leading-snug text-white md:text-center mb-4">
        {headline1}
      </h2>
      <p className="text-md leading-snug text-white md:text-center">
        {headline2}
      </p>
      <div className="mt-8 md:mt-16 flex justify-center flex-wrap">
        {androidBtnConfig && (
          <div className="w-full lg:w-2/5 lg:mr-2 mb-8">
            <DownloadButton btnConfig={androidBtnConfig}></DownloadButton>
          </div>
        )}
        {iosBtnConfig && (
          <div className="w-full lg:w-2/5 lg:ml-2 mb-8">
            <DownloadButton btnConfig={iosBtnConfig}></DownloadButton>
          </div>
        )}
      </div>
    </div>
    <div className="flex flex-col items-center overflow-hidden">
      <DeviceImage countryCode={countryCode} />
      <div
        style={{
          backgroundColor: COLORS[countryCode] || COLORS.pt,
          width: "100%",
          height: "2px",
        }}
      ></div>
    </div>
  </Layout>
);

export default CountryPage;
