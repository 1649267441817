import React from "react";
import CountryPage from "../../components/country-page";

const ANDROID_BTN_CONFIG = {
  os: "android",
  href:
    "https://play.google.com/store/apps/details?id=com.letiagoalves.radiotuga.france",
  target: "_blank",
  text: "Télécharger",
  bgColor: "#0055A4",
};

export default () => (
  <CountryPage
    countryCode="fr"
    appName="Radio France"
    androidBtnConfig={ANDROID_BTN_CONFIG}
    headline1="Téléchargez gratuitement Radio Frace et écoutez plus de 200 radios!"
    headline2="Radio France a été développé pour amener la radio française aux quatre coins du monde."
  />
);
